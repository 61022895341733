<template>
    <div class="flex flex-col w-full">


        <div class="flex flex-col items-start justify-between flex-shrink-0 sticky top-0 bg-white px-6 py-6 flex-shrink-0">
            <div class="text-gray-800 text-2xl">News</div>
        </div>


        <div class="flex flex-1 w-full">
            <autralis-loading-comp v-if="loading" size="sm"/>
            <div v-else-if="!loading && loadingError" class="flex rounded-md bg-red-50 p-4 items-center">
                <i class="fal fa-exclamation-circle text-red-400 mr-3"/>
                <h3 class="text-sm font-medium text-red-800">{{ loadingError }}</h3>
            </div>

            <div v-else class="news flex flex-col w-full mb-6 sm:mb-0">
                <div v-if="news.length === 0" class="text-center px-6">
                    <i class="fal fa-envelope text-gray-600 text-3xl"/>
                    <p class="mt-1 text-sm text-gray-500">No news at the moment.</p>
                </div>

                <div class="flex flex-col">
                    <div v-for="item in news" :key="item.id"
                         :class="['min-w-0 flex justify-between items-center flex-1 px-6 hover:bg-gray-50 cursor-pointer align-top py-2',
                         isNewsSelected(item.id) ? 'bg-gray-50' : '']"
                         @click="openNews(item.id)">
                        <div class="flex flex-col w-full mr-2 truncate">
                            <div class="text-sm font-medium text-gray-900 truncate">{{ item.title }}</div>
                            <div class="text-xs text-gray-500 truncate truncate">{{ formatDate(item.published) }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Loading from "@/components/loading";
import {head, orderBy} from "lodash";
import moment from "moment";
import {handleFetchNews} from "@/master/views/Home/news/handlers";

export default {
    name: "Panel",
    data() {
        return {
            loading: false,
            loadingError: null
        }
    },

    computed: {
        news() {
            return orderBy(this.$store.getters['master/news'], ['published'], ['desc']);
        }
    },

    methods: {
        openNews(news_id) {
            this.$store.commit('master/setSelectedNews', news_id)
        },

        isNewsSelected(news_id) {
            return this.$store.getters['master/selectedNews'] === news_id
        },


        formatDate(date) {
            return moment(date).format('DD/MM/YYYY, HH:mm') + ' (' + moment(date, "YYYYMMDD").fromNow() + ')';
        },

        async fetchNews() {
            try {
                this.loading = true;
                this.loadingError = null

                const payload = {};

                await handleFetchNews(payload, (response) => {
                    this.$store.commit('master/setNews', response.news);
                    // If there is only one news, select it
                    if (this.$store.getters['master/news'].length === 1) {
                        const item = head(this.$store.getters['master/news']);
                        if (item) {
                            this.$store.commit('master/setSelectedNews', item.id)
                        }
                    }
                }, (error) => {
                    this.loadingError = error
                })

                this.loading = false;

            } catch (err) {
                this.loadingError = 'The news could not be fetched. Please try again.'
                this.loading = false;
            }
        }
    },

    created() {
        this.fetchNews();
    },

    components: {
        'autralis-loading-comp': Loading
    }
}
</script>

<style scoped>
@media (max-width: 1023px) {
    .news {
        height: 156px;
        overflow-y: scroll;
    }
}
</style>